// Classes
import { PrimitiveTools } from '@/Classes/Static/PrimitiveTools'
import { ValueTools }     from '@/Classes/Static/ValueTools'
import { VuexTools }      from '@/Classes/Static/VuexTools'

// Components (.vue)
import BasicHeader from '@/Components/Global/BasicHeader/template.vue'
import SidePanel   from '@/Components/Global/SidePanel/template.vue'
import ModuleBox   from '@/Components/Modules/1/ModuleBox/template.vue'

// Components (Refs)
import { BasicHeaderRef } from '@/Components/Global/BasicHeader/component'
import { SidePanelRef }   from '@/Components/Global/SidePanel/component'

// Constants
import { AppValues }   from '@/Constants/Global/AppValues' 
import { Breakpoints } from '@/Constants/Global/Breakpoints'
import { Server }      from '@/Constants/Global/Server'
import { VueRouter }   from '@/Constants/Global/VueRouter'
import { Vuex }        from '@/Constants/Global/Vuex'
import { Module1 }     from '@/Constants/Modules/Module1'

// Dependencies
import VueMixins from 'vue-typed-mixins'

// Mixins
import MixinBase  from '@/Mixins/MixinBase'
import MixinFetch from '@/Mixins/MixinFetch'
import MixinResponsive from '@/Mixins/MixinResponsive'

// Store
import Store from '@/Store/Global/Default'

// Component Export
export default VueMixins(MixinBase, MixinFetch,MixinResponsive).extend({
	name: VueRouter.Modules.View11.NAME,

	components: {
		BasicHeader,
		ModuleBox,
		SidePanel
	},

	props: {
		unauthorizedRedirect: Boolean
	},

	data: function() {
		return {
			permissions: {
				[AppValues.ObjectIds.Module40.toUpperCase()]: null
			}
		}
	},

	created: function() {
		// Validación para la Redirección no Autorizada.
		const { unauthorizedRedirect } = this.$props
		if (unauthorizedRedirect) this.showToast('Acceso No Autorizado', 'No tienes permisos para acceder al Sistema.', 'danger')

		// this.doFetchValidationFor(Server.Fetching.Validations.COMPANIES)
		this.doFetchValidationFor(Server.Fetching.Validations.PERMISSIONS)
		// this.doFetchValidationFor(Server.Fetching.Validations.STORAGES)
		this.doFetchValidationFor(Server.Fetching.Validations.SYSTEMS)
	},

	mounted: function() {
		this._sidePanel.setItems(Module1.M11.SidePanelItems)
		Store.commit('updateViewsViewMode', VueRouter.ViewModes.EXTERNAL)
		this._initPermissions()
	},

	computed: {
		_getSystems: function() {
			// Los Sistemas se almacenarán todos juntos en una misma propiedad.
			const storedSystems: Array<any> = Store.getters.getStoredSystems

			// El filtro será aplicado directamente en la vista, validando que exista al menos un sistema en el array.
			if (!PrimitiveTools.Arrays.isInvalidOrEmpty(storedSystems)) {
				// Obtener los Sistemas correspondientes a la constante de los Clientes.
				const externalSystemsIds = Object.keys(Module1.M11.ModulesList)
				
				// Array que debe contener solamente los Sistemas de los Ids extraidos de la constante.
				const systems = PrimitiveTools.Arrays.getFromStackByProperty(storedSystems, externalSystemsIds, '_idSystem')
				return systems.sort((a: any, b: any) => {
					const indexA = Module1.M11.ModulesList[a._idSystem]?._params.index
					const indexB = Module1.M11.ModulesList[b._idSystem]?._params.index
					return (indexA > indexB)  ? 1 : -1
				})
			}
		},

		_basicHeader: function(): BasicHeaderRef {
			return this.$refs.basicHeader as BasicHeaderRef
		},

		_sidePanel: function(): SidePanelRef {
			return this.$refs.sidePanel as SidePanelRef
		}
	},

	methods: {
		_getSystemData: function(system: System, property: string, subProperty: string) {
			const a = Module1.M11.ModulesList[system._idSystem]
			const b: any = a[property]
			return !ValueTools.isNullOrUndefined(subProperty) ? b[subProperty] : b
		},

		_hasPermissionToSystem: function(system: System) {
			return this.permissions[system._idSystem.toUpperCase()]
		},

		_initPermissions: function() {
			const userPermissions = Store.getters.getStoredUserPermissionsAsObject
			this._setPermission(AppValues.ObjectIds.Module40.toUpperCase(), userPermissions.ACCESS_MODULE_40?.privileges.read)
		},

		_setPermission: function(key: string, value: boolean) {
			this.permissions[key] = value
		},

		onSPItemClick: function(item: any) {
			this.$router.push({ name: item.action })
		},
		_onResponsiveBreakpoint: function(breakpoint: number) {
			// Cambios que afectan el aspecto visual de los Componentes (según Breakpoint).
			
			this._basicHeader.setStates<BasicHeaderRef['states']>({ isMobile: breakpoint <= Breakpoints.Medium })

		},
	},

	watch: {
		...VuexTools.watchStoreProperty(Vuex.Modules.Global.Names.Permissions, '_initPermissions')
	}
})

interface System {
	_idSystem: ObjectId
}